import {Environment, EnvironmentType} from "./environment-config";

export const environment: Environment = {
  production: true,
  environment: EnvironmentType.PROD,
  backendBaseUrl: 'https://app.propup.at/graphql',
  realFinanzWidgetBaseUrl: 'https://www.realfinanz.at/bundles/loan-calculator-propup/loan-calculator-widget.js',
  realFinanzWidgetModuleBaseUrl: 'https://www.realfinanz.at/bundles/loan-calculator-propup/loan-calculator.js',
  realFinanzWidgetStylingUrl: 'https://www.realfinanz.at/bundles/loan-calculator-propup/loan-calculator.css',
  OBJECT_BACKEND_URL: 'https://backend.propup.at/object-service/',
  SENTRY_ENVIRONMENT: 'production',
  staticToggles: {
    enableLoggingTracing: true,
    enableLoadingPage: false,
    showFeedbackModal: true,
    showObjectImages: true,
    enableServicePage: false,
    enableOwnerSigning: true,
    enableLogin: false,
  },
};